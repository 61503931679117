import React, { useRef, useEffect } from 'react'
import ImageWebp from '../../../../../components/ImageWebp/index'
import IcClose from '@interco/icons/core/action-navigation/ic_close'

import * as S from './style'

interface IJornadaDoCandidatoProps {
  JornadaDoCandidatoJSON: IJornadaMobileProps[];
  handleModal: (index: number) => void;
  handleCloseModal: () => void;
  images: string[];
  modal: number | null;
}

interface IJornadaMobileProps {
  image: string[];
  title: string;
  description: string;
  index: number;
}

const JornadaMobile = ({ JornadaDoCandidatoJSON, handleModal, handleCloseModal, images, modal }: IJornadaDoCandidatoProps) => {
  const modalRef = useRef<HTMLDivElement>(null)

  const handleClickOutside = (event: MouseEvent) => {
    if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
      handleCloseModal()
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <>
      {JornadaDoCandidatoJSON.map((item: IJornadaMobileProps, index: number) => {
        return (
          <div key={item.title} className='d-flex align-items-center'>
            <S.Image onClick={() => handleModal(index)} className='pb-5'>
              <ImageWebp
                arrayNumbers={[ 70, 0, 912, 1033 ]}
                arrayNumbersHeight={[ 70, 0, 956, 1082 ]}
                pathSrc={images[index]}
                altDescription=''
              />
            </S.Image>
            <div onClick={() => handleModal(index)} className='d-flex align-items-center pl-3'>
              <S.Text className='fs-18 lh-21 text-white fw-700 mb-5'>{item.title}</S.Text>
            </div>
            {modal === index && (
              <S.ModalContent ref={modalRef} className='bg-white rounded-16 position-absolute px-3 py-3'>
                <div className='d-flex justify-content-between'>
                  <S.Title className='fs-20 lh-24'>{item.title}</S.Title>
                  <div className='cursor-pointer'>
                    <IcClose onClick={() => handleCloseModal()} height={24} width={24} color='#FF7A00' />
                  </div>
                </div>
                <p className='fs-16 lh-19'>
                  {item.description}
                </p>
              </S.ModalContent>
            )}
          </div>
        )
      })}
    </>
  )
}

export default JornadaMobile
