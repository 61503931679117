import styled from 'styled-components'
import { breakpoints } from '../../../../styles/breakpoints'

import { orange } from '../../../../styles/colors'

export const Section = styled.section`
  background-color: ${orange.default};

  h2 {
    font-family: 'Citrina', Helvetica, sans-serif;
  }

  padding: 50px 0 50px 0;

  @media (min-width: ${breakpoints.md}) {
    background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/carreiras-pg2-dobra4-768/image.webp');
    background-repeat: no-repeat;
    height: 900px;
    background-size: auto;
    background-position: 40px 144px;
    padding: 50px 0 90px 0;
  }

  @media (min-width: ${breakpoints.lg}) {
    background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/carreiras-pg2-dobra4-1024/image.webp');
    background-position: center 128px;
    height: 830px;
    padding: 0 0 70px 0;
  }

  @media (min-width: ${breakpoints.xl}) {
    background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/carreiras-pg2-dobra4-1440/image.webp');
    height: 970px;
    padding-bottom: 110px;
  }
`

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
`

export const Title = styled.h2`
  @media (min-width: ${breakpoints.lg}) {
    position: relative;
    top: 90px;
    span {
      font-family: 'Citrina', Helvetica, sans-serif;
    }
  }
`
