import React from 'react'

// Components
import IconsSwitch from '../../../../components/IconsSwitch/_index'

// Hooks
import useDataLayer from '../../../../hooks/useDataLayer/dataLayerBody'

// Assets
import * as S from './style'

import FiquePorDentroJSON from '../../../assets/data/FiquePorDentro.json'

type FiquePorDentroProps = {
  icon: string;
  path: string;
  title: string;
  description: string;
  textLink: string;
  link: string;
}

const FiquePorDentro = () => {
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <S.Section className='py-5 d-flex align-items-center'>
      <div className='container'>
        <div className='row align-items-center mb-3'>
          <div className='col-12 mt-3 mt-md-0'>
            <h2 className='fs-24 fs-md-28 fs-lg-32 fs-xl-48 lh-28 lh-md-30 lh-lg-38 lh-xl-52 font-citrina text-white fw-500 text-center'>
              Fique por dentro das nossas novidades
            </h2>
            <p className='fs-14 fs-md-16 fs-xl-18 lh-16 lh-md-19 lh-xl-22 text-white text-center mt-3'>
              Acompanhe nosso blog e nossas redes sociais para ficar por dentro do que acontece aqui no Inter.
            </p>
          </div>
        </div>
        <div className='row'>
          {FiquePorDentroJSON.map((item: FiquePorDentroProps, index: number) => {
            return (
              <div className='col-12 col-lg-6 mb-3' key={index}>
                <S.Card className='px-4 py-4'>
                  <div className='d-flex align-items-center mb-3'>
                    <IconsSwitch
                      icon={item.icon}
                      width='24'
                      height='24'
                      color='orange'
                      lib='interco'
                      customPath={item.path}
                      className='mr-2'
                    />
                    <div><S.Title className='fw-700 text-orange--extra mb-0'>{item.title}</S.Title></div>
                  </div>
                  <div><S.Description className='fw-600 text-grayscale--500'>{item.description}</S.Description></div>
                  <div className='d-flex align-items-center justify-content-between justify-content-md-start'>
                    <div className='mr-2'>
                      <a
                        onClick={() => {
                          sendDatalayerEvent({
                            section: 'dobra_8',
                            element_action: 'click button',
                            element_name: `${item.textLink} - ${item.title}`,
                            section_name: 'Fique por dentro das nossas novidades',
                            redirect_url: item.link,
                          })
                        }}
                        target='blank'
                        href={item.link}
                        title={item.textLink}
                      >
                        <S.TextLink className='fw-400 text-orange--extra mb-0'>{item.textLink}</S.TextLink>
                      </a>
                    </div>
                    <div>
                      <IconsSwitch
                        icon='ic_arrow_right'
                        width='24'
                        height='24'
                        color='orange'
                        lib='interco'
                        customPath='action-navigation/'
                      />
                    </div>
                  </div>
                </S.Card>
              </div>
            )
          })}
        </div>
      </div>
    </S.Section>
  )
}

export default FiquePorDentro
