import React from 'react'

// Components
import IconsSwitch from '../../../../components/IconsSwitch/_index'
import DefaultCarousel from '../../../../components/Carousels/DefaultCarousel/index'

// Hooks
import useWidth from '../../../../hooks/window/useWidth'

import DicasJSON from '../../../assets/data/Dicas.json'

import * as S from './style'

type DicasProps = {
  description: string;
  icon: string;
  path: string;
}

const WIDTH_MD = 768

const Dicas = () => {
  const windowWidth = useWidth(300)

  return (
    <S.Section className='py-5'>
      <div className='container'>
        <div className='row mt-xl-5'>
          <div className='col-12'>
            <h2 className='fs-24 fs-md-28 fs-lg-32 fs-xl-48 lh-28 lh-md-33 lh-lg-38 lh-xl-57 fw-500 text-center text-orange--argila mb-3 mb-md-5'>Dicas</h2>
          </div>
        </div>
        <div className='row'>
          {windowWidth < WIDTH_MD ? (
            <div className='col-12'>
              <DefaultCarousel
                sm={{ items: 1, partialVisibilityGutter: 43 }}
                md={{ items: 2, partialVisibilityGutter: 80 }}
                lg={{ items: 3, partialVisibilityGutter: 37 }}
                xl={{ items: 3, partialVisibilityGutter: 85 }}
              >
                {DicasJSON.map((item: DicasProps) => (
                  <div className='col-12' key={item.icon}>
                    <S.Card className='bg-white rounded-4 box-shadow-1 px-4 py-4'>
                      <div>
                        <div className='mb-3'>
                          <IconsSwitch
                            icon={item.icon}
                            width='24'
                            height='24'
                            color='#EA7100'
                            lib='interco'
                            customPath={item.path}
                          />
                        </div>
                        <p className='fs-14 fs-md-16 lh-16 lh-md-19 text-grayscale--500 mb-0' dangerouslySetInnerHTML={{ __html: item.description }} />
                      </div>
                    </S.Card>
                  </div>
                ))}
              </DefaultCarousel>
            </div>
          ) : (
            <>
              {DicasJSON.map((item: DicasProps) => (
                <div className='col-md-6 col-lg-4 mb-md-4 mt-md-3' key={item.icon}>
                  <S.Card className='bg-white px-4 py-4'>
                    <div>
                      <div className='mb-3'>
                        <IconsSwitch
                          icon={item.icon}
                          width='24'
                          height='24'
                          color='#EA7100'
                          lib='interco'
                          customPath={item.path}
                        />
                      </div>
                      <p className='fs-14 fs-md-16 lh-16 lh-md-19 text-grayscale--500 mb-0' dangerouslySetInnerHTML={{ __html: item.description }} />
                    </div>
                  </S.Card>
                </div>
              ))}
            </>
          )}
        </div>
      </div>
    </S.Section>
  )
}

export default Dicas
