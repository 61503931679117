import styled from 'styled-components'
import { orange, white } from '../../../../styles/colors'
import { Link } from 'gatsby'
import breakpoints from '../../../../styles/breakpoints'

export const Section = styled.section`
  background-color: ${orange.argila};
  padding-top: 79px;  


    @media(min-width: ${breakpoints.md}) {
      height: 391px;
      padding-top: 98px;
    }

    @media(min-width: ${breakpoints.lg}) {
      height: 521px;
    }
`

export const Btn = styled(Link)`
  width: 100%;
  height: 48px;
  background: ${orange.default};
  border-radius: 8px;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: ${white};
  display: flex;
  align-items: center;
  justify-content: center;

  &:focus, &:hover {
    outline: none;
    text-decoration: none;
    color: ${white};
    opacity: 0.9;
  }
`
