
import React from 'react'

// components
import Layout from '../../components/Layout/index'

// sections
import Hero from './sections/hero/_index'
import AreasDeAtuacao from './sections/areas-de-atuacao/_index'
import MuitosBeneficios from './sections/muitos-beneficios/_index'
import JornadaDoCandidato from './sections/jornada-do-candidato/_index'
import Dicas from './sections/dicas/_index'
import FiquePorDentro from './sections/fique-por-dentro/_index'

// assets
import { Wrapper } from './style'

import pageContext from './pageContext.json'

const Home = () => {
  return (
    <Wrapper>
      <Layout pageContext={pageContext}>
        <Hero />
        <AreasDeAtuacao />
        <MuitosBeneficios />
        <JornadaDoCandidato />
        <Dicas />
        <FiquePorDentro />
      </Layout>
    </Wrapper>
  )
}

export default Home
