import React, { useRef, useEffect } from 'react'

// Components
import ImageWebp from '../../../../../components/ImageWebp/index'

import IcClose from '@interco/icons/core/action-navigation/ic_close'
import * as S from './style'

interface IJornadaDesktopProps {
  image: string[];
  title: string;
  description: string;
  index: number;
  id: number;
}

interface IJornadaDoCandidatoProps {
  JornadaDoCandidatoJSON: IJornadaDesktopProps[];
  images: string[];
  openItemId: number | null;
  setOpenItemId: (id: number | null) => void;
}

const JornadaDesktop = ({ JornadaDoCandidatoJSON, images, openItemId, setOpenItemId }: IJornadaDoCandidatoProps) => {
  const modalRef = useRef<HTMLDivElement>(null)

  const handleModal = (itemId: number) => {
    setOpenItemId(itemId)
  }

  const handleCloseModal = () => {
    setOpenItemId(null)
  }

  const handleClickOutside = (event: MouseEvent) => {
    if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
      handleCloseModal()
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const indexDisplayContentTwo = [ 2, 3 ]
  const displayOrderContentTwo = [ 1, 0 ]

  return (
    <>
      <div className='d-flex'>
        {JornadaDoCandidatoJSON.slice(0, 2).map((item: IJornadaDesktopProps) => (
          <S.ContentOne key={item.title} className='d-flex align-items-center'>
            <div className='d-flex flex-column justify-content-center'>
              <S.Content>
                <div className='cursor-pointer' onClick={() => handleModal(item.id)}>
                  <ImageWebp
                    arrayNumbers={[ 0, 76, 76, 76, 76 ]}
                    arrayNumbersHeight={[ 0, 76, 76, 76, 76 ]}
                    pathSrc={images[JornadaDoCandidatoJSON.indexOf(item)]}
                    altDescription=''
                  />
                </div>
              </S.Content>
              <S.Content className='text-center'>
                <S.Text className='fs-18 fs-xl-24 lh-21 lh-xl-28 text-white fw-700'>{item.title}</S.Text>
              </S.Content>
              {openItemId === item.id && (
                <S.ModalContent ref={modalRef} className='bg-white rounded-16 position-absolute px-3 py-3'>
                  <div className='d-flex justify-content-between'>
                    <S.Title className='fs-20 lh-24'>{item.title}</S.Title>
                    <div className='cursor-pointer'>
                      <IcClose onClick={() => handleCloseModal()} height={24} width={24} color='#FF7A00' />
                    </div>
                  </div>
                  <p className='fs-16 lh-19'>
                    {item.description}
                  </p>
                </S.ModalContent>
              )}
            </div>
          </S.ContentOne>
        ))}
      </div>
      <div className='d-flex'>
        {JornadaDoCandidatoJSON
          .filter((item: IJornadaDesktopProps, index: number) => indexDisplayContentTwo.includes(index))
          .sort((a, b) => displayOrderContentTwo[indexDisplayContentTwo.indexOf(JornadaDoCandidatoJSON.indexOf(a))] - displayOrderContentTwo[indexDisplayContentTwo.indexOf(JornadaDoCandidatoJSON.indexOf(b))])
          .map((item: IJornadaDesktopProps) => (
            <S.ContentTwo key={item.title} className='d-flex align-items-center'>
              <div className='d-flex flex-column justify-content-center'>
                <S.Content>
                  <div className='cursor-pointer' onClick={() => handleModal(item.id)}>
                    <ImageWebp
                      arrayNumbers={[ 0, 76, 76, 76, 76 ]}
                      arrayNumbersHeight={[ 0, 76, 76, 76, 76 ]}
                      pathSrc={images[JornadaDoCandidatoJSON.indexOf(item)]}
                      altDescription=''
                    />
                  </div>
                </S.Content>
                <S.Content className='text-center'>
                  <S.Text className='fs-18 fs-xl-24 lh-21 lh-xl-28 text-white fw-700'>{item.title}</S.Text>
                </S.Content>
                {openItemId === item.id && (
                  <S.ModalContent ref={modalRef} className='bg-white rounded-16 position-absolute px-3 py-3'>
                    <div className='d-flex justify-content-between'>
                      <S.Title className='fs-20 lh-24'>{item.title}</S.Title>
                      <div className='cursor-pointer'>
                        <IcClose onClick={() => handleCloseModal()} height={24} width={24} color='#FF7A00' />
                      </div>
                    </div>
                    <p className='fs-16 lh-19'>
                      {item.description}
                    </p>
                  </S.ModalContent>
                )}
              </div>
            </S.ContentTwo>
          ))}
      </div>
      <div className='d-flex'>
        {JornadaDoCandidatoJSON.slice(4, 6).map((item: IJornadaDesktopProps, index: number) => (
          <S.ContentThree key={item.title} className='d-flex align-items-center'>
            <div className='d-flex flex-column justify-content-center'>
              <S.Content>
                <div className='cursor-pointer' onClick={() => handleModal(item.id)}>
                  <ImageWebp
                    arrayNumbers={[ 0, 76, 76, 76, 76 ]}
                    arrayNumbersHeight={[ 0, 76, 76, 76, 76 ]}
                    pathSrc={images[JornadaDoCandidatoJSON.indexOf(item)]}
                    altDescription=''
                  />
                </div>
              </S.Content>
              <S.Content className='text-center'>
                <S.Text className='fs-18 fs-xl-24 lh-21 lh-xl-28 text-white fw-700'>{item.title}</S.Text>
              </S.Content>
              {openItemId === item.id && (
                <S.ModalContent ref={modalRef} className='bg-white rounded-16 position-absolute px-3 py-3'>
                  <div className='d-flex justify-content-between'>
                    <S.Title className='fs-20 lh-24'>{item.title}</S.Title>
                    <div className='cursor-pointer'>
                      <IcClose onClick={() => handleCloseModal()} height={24} width={24} color='#FF7A00' />
                    </div>
                  </div>
                  <p className='fs-16 lh-19'>
                    {item.description}
                  </p>
                </S.ModalContent>
              )}
            </div>
          </S.ContentThree>
        ))}
      </div>
    </>
  )
}

export default JornadaDesktop
