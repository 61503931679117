import styled from 'styled-components'
import breakpoints from '../../../../../styles/breakpoints'
import { orange } from '../../../../../styles/colors'

export const Image = styled.div`
  background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/carreiras-pg2-dobra4-360/image.webp');
  background-repeat: no-repeat;
  background-position: center 40px;
  display: flex;
  justify-content: center;

  @media(min-width: ${breakpoints.md}) {
    background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/carreiras-pg2-dobra4-768/image.webp');
  }

  @media(min-width: ${breakpoints.lg}) {
    background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/carreiras-pg2-dobra4-1024/image.webp');
  }
`

export const ContentOne = styled.div`
  @media(min-width: ${breakpoints.md}) {    
    width: 550px;
    margin-top: -43px;
    margin-left: 80px;
  }
  @media(min-width: ${breakpoints.lg}) {    
    margin-left: 200px;
    margin-top: -64px;
  }
  @media(min-width: ${breakpoints.xl}) {    
    margin-left: 310px;
    margin-top: -64px;
  }
  @media(min-width: ${breakpoints.xxl}) {    
    width: 110px;
    margin-left: 28%;
    margin-top: -74px;
  }
`

export const ContentTwo = styled.div`
  @media(min-width: ${breakpoints.md}) {
    width: 480px;
    margin-top: 157px;
    margin-left: 60px;
  }
  @media(min-width: ${breakpoints.lg}) {    
    margin-top: 141px;
  }
  @media(min-width: ${breakpoints.xl}) {    
    margin-top: 192px;
  }
  @media(min-width: ${breakpoints.xxl}) {    
    width: 280px;
    margin-left: 260px;
    margin-top: 202px;
  }
`

export const ContentThree = styled.div`
  @media(min-width: ${breakpoints.md}) {
    width: 480px;
    margin-top: 135px;
    margin-left: 113px;
  }
  @media(min-width: ${breakpoints.lg}) { 
    margin-left: 230px;
    margin-top: 104px;
  }
  @media(min-width: ${breakpoints.xl}) {    
    margin-left: 304px;
    margin-top: 144px;
  }
  @media(min-width: ${breakpoints.xxl}) {    
    width: 180px;
    margin-left: 31%;
    margin-top: 144px;
  }
`

export const Content = styled.div`
  width: 200px;
  display: flex;
  justify-content: center;
`

export const ModalContent = styled.div`
  z-index: 1;
 
  @media(min-width: ${breakpoints.md}) {
  width: 270px;
 }
`

export const Title = styled.h3`
  font-family: 'Citrina', Helvetica, sans-serif;
  color: ${orange.default};
`

export const Text = styled.p`
  font-family: 'Citrina', Helvetica, sans-serif;
`
