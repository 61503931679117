import React from 'react'

// Components
import ImageWebp from '../../../../components/ImageWebp/index'

// Hooks
import useDataLayer from '../../../../hooks/useDataLayer/dataLayerBody'

// Assets
import * as S from './style'

const SuperAppFinanceiro = () => {
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <S.Section className='pb-5 d-flex align-items-center'>
      <div className='container'>
        <div className='row align-items-center'>
          <div className='col-12 col-md-6 offset-md-1 d-flex justify-content-center order-md-last'>
            <ImageWebp
              arrayNumbers={[ 312, 354, 514, 564 ]}
              pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/carreiras-pg2-hero/image.webp'
              altDescription='Mulher de cabelo ruivo sorrindo'
            />
          </div>
          <div className='col-12 col-md-5 mt-3'>
            <h1 className='fs-24 fs-md-28 fs-lg-32 fs-xl-48 lh-28 lh-md-30 lh-lg-38 lh-xl-52 font-citrina text-white fw-500 text-center text-md-left'>
              Considere seu futuro de trabalho no Inter
            </h1>
            <p className='fs-14 fs-md-16 fs-xl-18 lh-16 lh-md-19 lh-xl-22 text-white text-center text-md-left mt-3'>
              Confira os benefícios, a jornada do candidato e outras informações valiosas para iniciar sua carreira no Inter.
            </p>
            <S.Btn
              onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_1',
                  element_action: 'click button',
                  element_name: 'Conferir as vagas',
                  section_name: 'Considere seu futuro de trabalho no Inter',
                  redirect_url: 'carreiras',
                })
              }}
              to='/carreiras'
              title='Veja nossas vagas disponíveis'
              className='fs-14 text-center rounded-8 px-5 py-2 mt-md-2'
            >
              Conferir as vagas
            </S.Btn>
          </div>
        </div>
      </div>
    </S.Section>
  )
}

export default SuperAppFinanceiro
