import styled from 'styled-components'
import { breakpoints } from '../../../../styles/breakpoints'

import { orange, grayscale } from '../../../../styles/colors'

export const Section = styled.section`
  background-color: #FDF8EE;

  @media (min-width: ${breakpoints.xl}) {
    height: 791px;
  }

  .react-multi-carousel-dot-list {
    @media (min-width: ${breakpoints.md}) {
      bottom: 5px;
    }
  }

  button {
    svg {
      fill: ${orange.default};
    }
  }

  .react-multiple-carousel__arrow--left {
    left: 0;

    @media (min-width: ${breakpoints.md}) {
      left: 160px;
    }

    @media (min-width: ${breakpoints.lg}) {
      left: 230px;
    }

    @media (min-width: ${breakpoints.xl}) {
      left: 260px;
    }
  }

  .react-multiple-carousel__arrow--right {
    right: 0;

    @media (min-width: ${breakpoints.md}) {
      right: 160px;
    }

    @media (min-width: ${breakpoints.lg}) {
      right: 230px;
    }

    @media (min-width: ${breakpoints.xl}) {
      right: 260px;
    }
  }

  .react-multi-carousel-dot {
    button {
      background: ${orange.default};
      border: none;
      width: 12px;
      height: 4px;
    }
  }

  .react-multi-carousel-dot--active {
    button {
      border: none;
    }
  }
`

export const Card = styled.div`
  border-radius: 14px;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);;

  @media (min-width: ${breakpoints.md}) {
    height: 460px;
  }

  @media (min-width: ${breakpoints.lg}) {
    height: 460px;
  }
`
