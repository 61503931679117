import React, { useState } from 'react'

// Hooks
import useDataLayer from '../../../../hooks/useDataLayer/dataLayerBody'

import DefaultCarousel from '../../../../components/Carousels/DefaultCarousel/index'

import AreasDeAtuacaoJSON from '../../../assets/data/AreasDeAtuacao.json'
import ImageWebp from '../../../../components/ImageWebp'

import * as S from './style'

type AreasDeAtuacaoProps = {
  title: string;
  description: string;
  image: string;
  altImage: string;
  link: string;
}

const AreasDeAtuacao = () => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const [ positon, setPosition ] = useState(0)

  return (
    <S.Section className='py-5 d-flex align-items-md-center'>
      <div className='container'>
        <div className='row align-items-center'>
          <div className='col-12 col-md-5 d-flex justify-content-center justify-content-md-start'>
            <ImageWebp
              arrayNumbers={[ 312, 258, 366, 450 ]}
              arrayNumbersHeight={[ 312, 258, 366, 450 ]}
              pathSrc={(AreasDeAtuacaoJSON[positon].image)}
              altDescription={AreasDeAtuacaoJSON[positon].altImage}
            />
          </div>
          <div className='col-12 col-md-6 offset-md-1'>
            <DefaultCarousel
              sm={{ items: 1 }}
              md={{ items: 1 }}
              lg={{ items: 1 }}
              xl={{ items: 1 }}
              beforeChange={(nextSlide: number) => setPosition(nextSlide)}
            >
              {AreasDeAtuacaoJSON.map((item: AreasDeAtuacaoProps) => (
                <div key={item.title}>
                  <h2 className='fs-24 fs-md-28 fs-lg-32 fs-xl-48 lh-28 lh-md-33 lh-lg-38 lh-xl-57 fw-500 text-white mb-3'>Áreas de atuação</h2>
                  <h3 className='text-white fw-500'>{item.title}</h3>
                  <p className='fs-14 fs-md-16 fs-lg-18 lh-16 lh-md-19 lh-lg-22 text-white fw-500 mb-4'>{item.description}</p>
                  <S.Btn
                    onClick={() => {
                      sendDatalayerEvent({
                        section: 'dobra_2',
                        element_action: 'click button',
                        element_name: `Conferir as vagas - ${item.title}`,
                        section_name: 'Áreas de atuação',
                        redirect_url: '/carreiras',
                      })
                    }}
                    to={item.link}
                    title='Veja nossas vagas disponíveis'
                    className='fs-14 text-center rounded-8 px-5 py-2 mt-md-2'
                  >
                    Conferir as vagas
                  </S.Btn>
                </div>
              ))}
            </DefaultCarousel>
          </div>
        </div>
      </div>
    </S.Section>
  )
}

export default AreasDeAtuacao
