import React, { useState } from 'react'

// Components
import JornadaMobile from './components/_JornadaMobile'
import JornadaDesktop from './components/_JornadaDesktop'

// Hooks
import useWidth from '../../../../hooks/window/useWidth'

// Images
import Candidatura from '../../../assets/images/candidatura.png'
import TesteECases from '../../../assets/images/teste-e-cases.png'
import EntrevistasTimePeopleCulture from '../../../assets/images/entrevistas-time-people-culture.png'
import EntrevistaComLideranca from '../../../assets/images/entrevista-com-a-lideranca.png'
import PropostaInter from '../../../assets/images/proposta-inter.png'
import Feedback from '../../../assets/images/feedback.png'

import JornadaDoCandidatoJSON from '../../../assets/data/JornadaDoCandidato.json'

import * as S from './style'

const images = [
  Candidatura, TesteECases, EntrevistasTimePeopleCulture, EntrevistaComLideranca, PropostaInter, Feedback,
]

const WIDTH_MD = 768

const JornadaDoCandidato = () => {
  const windowWidth = useWidth(300)
  const [ modal, setModal ] = useState<number | null>(null)
  const [ openItemId, setOpenItemId ] = useState<number | null>(null)

  const handleModal = (index: number) => {
    setModal(modal === index ? null : index)
  }

  const handleCloseModal = () => {
    setModal(null)
  }

  return (
    <S.Section className='d-flex align-items-lg-center'>
      <div className='container'>
        <div className='row mb-md-4'>
          <div className='col-12'>
            <S.Title className='fs-24 fs-md-28 fs-lg-32 fs-xl-48 lh-28 lh-md-33 lh-lg-38 lh-xl-55 fw-500 text-center text-lg-left text-white mb-3 mb-5'>
              <span className='d-lg-block'>Jornada do</span> candidato
            </S.Title>
          </div>
        </div>
        <div className='row align-items-center'>
          <div className='col-12'>
            <div>
              {windowWidth < WIDTH_MD ?
                <JornadaMobile
                  JornadaDoCandidatoJSON={JornadaDoCandidatoJSON}
                  handleModal={handleModal}
                  handleCloseModal={handleCloseModal}
                  images={images}
                  modal={modal}
                /> :
                <JornadaDesktop
                  JornadaDoCandidatoJSON={JornadaDoCandidatoJSON}
                  images={images}
                  openItemId={openItemId}
                  setOpenItemId={setOpenItemId}
                />}
            </div>
          </div>
        </div>
      </div>
    </S.Section>
  )
}

export default JornadaDoCandidato
