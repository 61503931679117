import styled from 'styled-components'
import { breakpoints } from '../../../../styles/breakpoints'
import { Link } from 'gatsby'
import { orange, white } from '../../../../styles/colors'

export const Section = styled.section`
  background-color: ${orange.default};

  h3 {
    font-size: 18px;
    line-height: 21px;
    font-family: 'Inter', Helvetica, sans-serif!important;

    @media (min-width: ${breakpoints.md}) {
      font-size: 24px;
      line-height: 28px;
    }

    @media (min-width: ${breakpoints.lg}) {
      font-size: 20px;
      line-height: 24px;
    }

    @media (min-width: ${breakpoints.xl}) {
      font-size: 32px;
      line-height: 38px;
    }
  }

  @media (min-width: ${breakpoints.md}) {
    height: 480px;
  }

  @media (min-width: ${breakpoints.lg}) {
    height: 462px;
  }

  @media (min-width: ${breakpoints.xl}) {
    height: 642px;
  }

  .react-multi-carousel-dot-list {
    @media (min-width: ${breakpoints.md}) {
      bottom: 5px;
    }
  }

  button {
    svg {
      fill: ${white};
    }
  }

  .react-multiple-carousel__arrow--left {
    left: 0;
  }

  .react-multiple-carousel__arrow--right {
    right: 0;
  }

  .react-multi-carousel-dot {
    button {
      background: ${white};
      border: none;
      width: 12px;
      height: 4px;
    }
  }

  .react-multi-carousel-dot--active {
    button {
      background: ${white};
      border: none;
    }
  }

  .react-multi-carousel-dot-list {
    .react-multi-carousel-dot {
      button {
        background-color: ${white};
      }
    }
  }
`

export const Btn = styled(Link)`
  width: 100%;
  height: 48px;
  background: ${white};
  border-radius: 8px;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: ${orange.default};
  display: flex;
  align-items: center;
  justify-content: center;
`
