import styled from 'styled-components'
import { breakpoints } from '../../../../styles/breakpoints'

import { orange, grayscale, brand } from '../../../../styles/colors'

export const Section = styled.section`
  background-color: ${brand.sand};
  background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/graf-360/image.webp');
  background-repeat: no-repeat;
  background-size: 47%;
  background-position: 13px 107px;

  @media (min-width: ${breakpoints.md}) {
    background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/graf-768/image.webp');
    background-size: 750px;
    height: 1130px;
  }

  @media (min-width: ${breakpoints.lg}) {
    background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/graf-1024/image.webp');
    background-size: 990px;
    height: 800px;
    background-position: 20px 118px;
  }

  @media (min-width: ${breakpoints.xl}) {
    background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/graf-1440/image.webp');
    height: 860px;
    background-size: 1190px;
    background-position: center;
  }

  @media (min-width: ${breakpoints.xxl}) {
    background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/graf-1440/image.webp');
    background-size: contain;
    background-position: center;
    height: 1000px;
  }

  a {
    color: ${grayscale[500]};
    text-decoration: underline;
  }

  .react-multi-carousel-dot-list {
    @media (min-width: ${breakpoints.md}) {
      bottom: 5px;
    }
  }

  button {
    svg {
      fill: ${orange.default};
    }
  }

  .react-multiple-carousel__arrow--left {
    left: 0;

    @media (min-width: ${breakpoints.md}) {
      left: 160px;
    }

    @media (min-width: ${breakpoints.lg}) {
      left: 230px;
    }

    @media (min-width: ${breakpoints.xl}) {
      left: 260px;
    }
  }

  .react-multiple-carousel__arrow--right {
    right: 0;

    @media (min-width: ${breakpoints.md}) {
      right: 160px;
    }

    @media (min-width: ${breakpoints.lg}) {
      right: 230px;
    }

    @media (min-width: ${breakpoints.xl}) {
      right: 260px;
    }
  }

  .react-multi-carousel-dot {
    button {
      background: ${orange.default};
      border: none;
      width: 12px;
      height: 4px;
    }
  }

  .react-multi-carousel-dot--active {
    button {
      border: none;
    }
  }
`

export const Card = styled.div`
  height: 260px;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
  border-radius: 14px;

  @media (min-width: ${breakpoints.md}) {
    height: 260px;
  }

  @media (min-width: ${breakpoints.lg}) {
    height: 250px;
  }
`
