import styled from 'styled-components'
import { orange } from '../../../../styles/colors'
import breakpoints from '../../../../styles/breakpoints'

export const Section = styled.section`
  background-color: ${orange.argila};

  @media(min-width: ${breakpoints.xl}) {
    height: 636px;
  }
`

export const Card = styled.div`
  background-color: #FCF8EE;
  border-radius: 14px;
`

export const Title = styled.p`
  font-size: 20px;
  line-height: 24px;

  @media(min-width: ${breakpoints.lg}) {
    font-size: 13px;
    line-height: 16px;
  }

  @media(min-width: ${breakpoints.xl}) {
    font-size: 16px;
    line-height: 19px;
  }
`

export const Description = styled.p`
  font-size: 16px;
  line-height: 19px;

  @media(min-width: ${breakpoints.md}) {
    font-size: 18px;
    line-height: 21px;
  }

  @media(min-width: ${breakpoints.lg}) {
    font-size: 13px;
    line-height: 16px;
  }

  @media(min-width: ${breakpoints.xl}) {
    font-size: 16px;
    line-height: 19px;
  }
`

export const TextLink = styled.p`
  font-size: 16px;
  line-height: 19px;

  @media(min-width: ${breakpoints.md}) {
    font-size: 20px;
    line-height: 24px;
  }

  @media(min-width: ${breakpoints.lg}) {
    font-size: 11px;
    line-height: 14px;
  }

  @media(min-width: ${breakpoints.xl}) {
    font-size: 14px;
    line-height: 16px;
  }
`
