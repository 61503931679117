import React from 'react'

// Components
import IconsSwitch from '../../../../components/IconsSwitch/_index'
import DefaultCarousel from '../../../../components/Carousels/DefaultCarousel/index'

import MuitosBeneficiosJSON from '../../../assets/data/MuitosBeneficios.json'

import * as S from './style'

type MuitosBeneficiosProps = {
  title: string;
  description: string;
  icon: string;
  path: string;
}

const MuitosBeneficios = () => {
  return (
    <S.Section className='py-5 d-flex align-items-center'>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <h2 className='fs-24 fs-md-28 fs-lg-32 fs-xl-48 lh-28 lh-md-33 lh-lg-38 lh-xl-57 fw-500 text-center text-orange--argila mb-3'>São muitos benefícios</h2>
          </div>
        </div>
        <div className='row'>
          <div className='col-12'>
            <DefaultCarousel
              sm={{ items: 1 }}
              md={{ items: 2, partialVisibilityGutter: 80 }}
              lg={{ items: 3, partialVisibilityGutter: 37 }}
              xl={{ items: 3, partialVisibilityGutter: 85 }}
            >
              {MuitosBeneficiosJSON.map((item: MuitosBeneficiosProps) => (
                <div className='col-12' key={item.title}>
                  <S.Card className='bg-white box-shadow-1 px-4 py-4'>
                    <div>
                      <div className='mb-3'>
                        <IconsSwitch
                          icon={item.icon}
                          width='24'
                          height='24'
                          color='#EA7100'
                          lib='interco'
                          customPath={item.path}
                        />
                      </div>
                      <h3 className='fs-18 fs-lg-20 fs-xl-24 lh-21 lh-lg-24 lh-xl-28 text-grayscale--500'>{item.title}</h3>
                      <p className='fs-14 fs-md-16 lh-16 lh-md-19 text-gray--800 mb-0'>{item.description}</p>
                    </div>
                  </S.Card>
                </div>
              ))}
            </DefaultCarousel>
          </div>
        </div>
      </div>
    </S.Section>
  )
}

export default MuitosBeneficios
